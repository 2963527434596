import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Form, FormError, FormItem, FormLabel } from 'src/ui/form';
import { Input } from 'src/ui/input';
import { Button } from '@components/ButtonV2';
import SocialAuth from '@ecosystems/socialAuth';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from 'src/ui/select';
import { useRouter } from 'next/router';
import AnalyticsManager from '@lib/analytics/manager';
import { Trans } from '@lingui/react';
import {
  FunnelKind,
  MethodTypes,
  ProviderTypes,
  RegistrationSourceKind,
  useSignUpMutation,
  useSocialSignUpMutation,
} from '@gql/generated';
import useFlags from '@lib/hooks/useFlags';
import CookieManager from '@lib/CookieManager';
import { CAMPAIGN_CODE_COOKIE, LANGUAGES } from '@lib/constants';
import { UserContext } from '@lib/contexts/UserProvider';
import LanguageContext from '@lib/contexts/languageContext';
import { PasswordInput } from 'src/ui/passwordInput';
import LoginModal from '@organisms/modals/LoginModal';
import { t } from '@lingui/macro';
import { useTrackRegistration } from '@lib/hooks/useTrackRegistration';

export const Errors = styled.ul`
  max-width: 500px;
  text-align: center;
  list-style: none;
  padding-left: 0;
  margin: 0 auto;
`;

export const Error = styled.li`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary['red-1']};
  margin: 0;
`;

const formSchema = z.object({
  email: z.string().email({
    message: t`validation.email`,
  }),
  password: z
    .string({ required_error: t`validation.required` })
    .min(8, { message: t`validation.passwordMin` })
    .max(21, { message: t`validation.passwordMax` }),
});

const ERRORS = {
  'Email is taken': <Trans id="validation.email_taken" />,
  'Email already Exists': <Trans id="validation.email_taken" />,
  'User already Exists': <Trans id="validation.user_social_already_exists" />,
};

const cleanUpRegisterCookies = () => {
  CookieManager.remove({
    key: CAMPAIGN_CODE_COOKIE,
  });
};

type Props = {
  // initialValues?: Record<'email', string>;
  // onSubmit(): void;
  // loading: boolean;

  registerSource: {
    source: RegistrationSourceKind;
    source_id: string;
  };
  trialCode?: string;
};

export function SignupForm(props: Props) {
  const router = useRouter();
  const {
    paymentType,
    redirect_to,
    redirectTo,
    utmSource,
    campaign,
    utmMedium,
    utmCampaign,
    funnel = null,
  } = router.query;
  const redirectURL = redirect_to || redirectTo;

  const email = (router.query.email as string) || '';

  const trackStep = useTrackRegistration();
  const [, { refetch }] = useContext(UserContext);
  const lang = useContext(LanguageContext);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email,
    },
  });

  const [flags] = useFlags();
  const [errors, setErrors] = useState(null);

  const comingFromLive = String(router.query.to).match(
    /\/(se|en|fi|no)\/live\/*/
  );
  const comingFromProgram = String(router.query.to).match(
    /\/(en|se|no|fi)\/programs\/(?<slug>[\w-]+)\/get-started/
  );

  const registerSource = props.registerSource;
  let trialCode = (props.trialCode ||
    router.query.trialCode ||
    router.query.code) as string;
  if (!trialCode && typeof window !== 'undefined') {
    trialCode = CookieManager.get({ key: CAMPAIGN_CODE_COOKIE });
  }

  const extraData = {
    paymentType: (paymentType || null) as string,
    redirectTo: (redirectURL || null) as string,
    campaignCode: (campaign || null) as string,
    utmSource: (utmSource || null) as string,
    utmMedium: (utmMedium || null) as string,
    utmCampaign: (utmCampaign || null) as string,
  };

  const getQueryParams = () => {
    const {
      trialCode,
      code,
      campaign,
      paymentType,
      redirect_to,
      redirectTo,
      // used to redirect after the subscription page...
      to,
    } = router.query;

    const params = {};

    Object.entries({
      trialCode,
      code,
      campaign,
      paymentType,
      redirect_to,
      redirectTo,
      // used to redirect after the subscription page...
      to,
    }).forEach(([key, val]) => val && (params[key] = val));

    return params;
  };

  const redirectToNextStep = async () => {
    const [response] = await refetch();
    if (response?.data?.currentUser?.funnel === FunnelKind['Play']) {
      trackStep('registration');
    }
    if (flags?.enable_manual_trial) {
      const currentUser = response?.data?.currentUser;

      AnalyticsManager().setUserProperties(currentUser);

      if (currentUser?.trialRequired) {
        const params = getQueryParams();

        if (router.query.hasOwnProperty('code')) {
          router.push({
            pathname: `/${lang}/get-started/subscription`,
            query: params,
          });
        } else if (router.query.trialCode || router.query.campaignCode) {
          router.push({
            pathname: `/${lang}/get-started/trial`,
            query: params,
          });
        } else {
          router.push({
            pathname: `/${lang}/get-started`,
            query: params,
          });
        }
      } else {
        let url = `/${lang}/me/dashboard`;
        if (router.query.to) {
          url = router.query.to as string;
        } else if (currentUser?.funnel === FunnelKind['Wellness']) {
          url = `/${lang}/friskvardsbidrag`;
        }
        window.location.href = url;
      }
    }
  };

  const [socialSignup, { loading: socialSignupLoading }] =
    useSocialSignUpMutation({
      onCompleted(resp) {
        if (!resp.socialSignUp.success) {
          setErrors([
            {
              message:
                'An unexpected error happened! please try again or contact us.',
            },
          ]);
          window.scrollTo({ left: 0, top: 2000 });
        } else {
          AnalyticsManager().initLibraries({});
          AnalyticsManager().sendRegisterEvent({
            source: registerSource?.source || RegistrationSourceKind.Video,
          });
          redirectToNextStep();
        }
      },
      onError(errors) {
        setErrors(errors.graphQLErrors);
        window.scrollTo({ left: 0, top: 2000 });
      },
    });

  const createSocialUser = async (token, info, provider) => {
    try {
      await socialSignup({
        variables: {
          method: MethodTypes['Token'],
          provider,
          accessToken: token,
          identityToken: '',
          code: '',
          firstName: info?.firstName,
          lastName: info?.lastName,
          avatarFromUrl: info?.picture,
          registrationSource:
            registerSource?.source || RegistrationSourceKind.Video,
          registrationSourceId: registerSource?.source_id || '',
          ...extraData,
        },
      });

      AnalyticsManager().sendRegisterEvent({
        source: registerSource?.source || RegistrationSourceKind.Video,
      });
    } catch (ex) {
      //
    }
  };

  const [createUser, { loading: processingRequest }] = useSignUpMutation({
    onCompleted(resp) {
      if (resp?.signUpV2.errors?.length > 0) {
        setErrors([resp?.signUpV2?.errors[0]]);
        window.scrollTo({ left: 0, top: 2000 });
      }
    },
    onError(errors) {
      if (errors.networkError) {
        setErrors([
          {
            message: <Trans id="metatadata.errors.unexpectedError" />,
          },
        ]);
        window.scrollTo({ left: 0, top: 2000 });
      } else if (errors.graphQLErrors) {
        setErrors(errors.graphQLErrors);
      }
      window.scrollTo({ left: 0, top: 2000 });
    },
  });

  const {
    courseAccessCode,
    courseImg,
    courseTitle,
    courseAmount,
    courseOriginalAmount,
  } = router.query;

  const courseCookies = {
    courseAccessCode,
    courseImg,
    courseTitle,
    courseAmount,
    courseOriginalAmount,
  };

  const handleFormSubmit = async (data) => {
    const {
      email,
      password,
      // firstName,
      // lastName,
      // country,
      // gender,
      // birthday,
      // countryCode,
    } = data;

    try {
      const _funnel = funnel as FunnelKind;
      const response = await createUser({
        variables: {
          email,
          password,
          ...extraData,
          registrationSource:
            registerSource?.source || RegistrationSourceKind.Video,
          registrationSourceId: registerSource?.source_id || '',
          funnel: Object.values(FunnelKind).includes(_funnel) ? _funnel : null,
        },
      });

      if (response?.data?.signUpV2?.user?.id) {
        AnalyticsManager().initLibraries({});
        AnalyticsManager().sendRegisterEvent({
          source: registerSource?.source || RegistrationSourceKind.Video,
        });

        if (comingFromLive) {
          AnalyticsManager().sendLiveSignupEvent({
            event: String(router.query.to).replace(
              /\/(se|en|fi|no)\/live\/*/,
              ''
            ),
          });
        }
        if (comingFromProgram) {
          AnalyticsManager().sendSignupFromProgramPageEvent({
            title: comingFromProgram.groups?.slug,
          });
        }

        if (response.data.signUpV2.user?.email) {
          cleanUpRegisterCookies();
          redirectToNextStep();
        }
      }
    } catch (ex) {
      //
    }
  };

  return (
    <Form {...form}>
      <form
        className="flex-1 flex flex-col items-center gap-2 lg:gap-5"
        aria-labelledby="form-desc"
        onSubmit={form.handleSubmit(handleFormSubmit)}
      >
        <div className="flex-1 max-w-2xl w-full py-4">
          <div className="flex flex-col md:grid md:grid-cols-2 gap-x-5 gap-y-2 px-4">
            <FormItem className="w-full">
              <FormLabel className="text-[13px]">
                <Trans id="registration.landing.email" />*
              </FormLabel>
              <Input
                type="email"
                placeholder=""
                {...form.register('email')}
                data-testid="registerForm__email"
              />
              <FormError data-testid="registerForm__email--group--error">
                {form.formState?.errors?.email?.message ? (
                  <Trans id={form.formState?.errors?.email?.message} />
                ) : null}
              </FormError>
            </FormItem>
            <FormItem className="w-full">
              <FormLabel className="text-[13px]">
                <Trans id="registration.landing.password" />*
              </FormLabel>
              <PasswordInput
                name="password"
                type="password"
                {...form.register('password')}
                data-testid="registerForm__password"
              />
              <FormError data-testid="registerForm__password--group--error">
                {form.formState?.errors?.password?.message ? (
                  <Trans id={form.formState?.errors?.password?.message} />
                ) : null}
              </FormError>
            </FormItem>
          </div>
          <div className="w-full gap-5 px-4">
            <SocialAuth
              title={t({ id: 'registration.landing.socials_label' })}
              onFacebookAuth={(token, info) => {
                createSocialUser(token, info, ProviderTypes['Facebook']);
              }}
              onAppleAuth={(token, info) => {
                createSocialUser(token, info, ProviderTypes['Apple']);
              }}
              onGoogleAuth={(token, info) => {
                createSocialUser(token, info, ProviderTypes['Google']);
              }}
            />
            <p className="text-center font-medium text-sm">
              <Trans
                id="registration.landing.alreadymember"
                components={[<LoginButton key={0} />]}
              />
            </p>
          </div>
        </div>
        {errors ? (
          <Errors className="px-4" data-testid="registration.errors">
            {errors.map((error) => {
              let message = error.message;
              if (ERRORS.hasOwnProperty(error.message)) {
                message = ERRORS[error.message];
              }
              return <Error key={error.message}>{message}</Error>;
            })}
          </Errors>
        ) : null}
        <div className="max-w-2xl flex justify-between items-center w-full py-2 px-4">
          <p className="text-[13px] font-light text-dark my-0 opacity-80">
            ©YOGOBE 2024. All rights reserved.
          </p>
          <LanguageDropdown />
        </div>
        <div className="sticky bottom-0 flex w-full bg-white px-4 py-4 border-t border-t-neutral-300">
          <Button
            type="submit"
            className="max-w-2xl w-full justify-center mx-auto"
            loading={processingRequest || socialSignupLoading}
            data-testid="registerForm__submitBtn"
            rounded
          >
            <Trans id="register_v2.form.create_account_btn" />
          </Button>
        </div>
      </form>
    </Form>
  );
}

const LanguageDropdown = () => {
  const lang = useContext(LanguageContext);
  const router = useRouter();

  const onChange = (lang) => {
    router.replace({
      pathname: router.pathname,
      query: {
        ...router.query,
        lang,
      },
    });
  };

  const currentLanguage = LANGUAGES.find((language) => language.lang === lang);

  return (
    <Select defaultValue={lang} onValueChange={onChange}>
      <SelectTrigger className="w-[180px] text-primary font-medium gap-1 justify-end border-none [&>svg]:opacity-1">
        <SelectValue>{currentLanguage.trans}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {LANGUAGES.map((language) => (
          <SelectItem key={language.lang} value={language.lang}>
            {language.trans}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

const LoginButton = ({ children = null }) => {
  const [_, { refetch }] = useContext(UserContext);
  const router = useRouter();
  const { paymentType, redirectTo, redirect_to, to } = router.query;
  const redirectURL = (to || redirectTo || redirect_to) as string;

  return (
    <LoginModal
      noRegister
      callBack={(closeModal) => {
        refetch();
        closeModal();
        if (redirectURL) {
          window.location.href = redirectURL;
        } else if (paymentType) {
          router.push(
            {
              pathname: '/[lang]/subscription/[step]',
              query: { paymentType },
            },
            {
              pathname: `/${router.query.lang}/subscription/select`,
              query: { paymentType },
            }
          );
        } else {
          router.push(`/${router.query.lang}/videos/genres/all`);
        }
      }}
    >
      {(openModal) => (
        <Button onClick={openModal} link>
          {children}
        </Button>
      )}
    </LoginModal>
  );
};
